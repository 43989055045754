<template>
  <div id="divChangePasswordWrapper" class="content-layout-fixer">
    <div class="form-wrapper">
      <VueForm ref="form" :bodyPadding="['0', '0']">
        <VueInput
          :type="passwordType"
          name="currentPassword"
          id="currentPassword"
          ref="currentPassword"
          validatorName="Şifre"
          label="Mevcut Şifre"
          v-model="formModel.currentPassword"
          :messageOnTop="true"
          validationRules="required|max:20|min:1|is_not:newPassword"
        />
        <div class="space"></div>
        <VueInput
          :type="passwordType"
          name="newPassword"
          id="newPassword"
          ref="newPassword"
          validatorName="Şifre"
          label="Yeni Şifre"
          v-model="formModel.newPassword"
          :messageOnTop="true"
          validationRules="required|max:20|min:8|atLeastTwoChar"
          :validState="true"
        >
          <VueButton
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            as="a"
            :iconName="getIcon.name"
            :iconSize="{ width: getIcon.width, height: getIcon.height }"
            :iconColor="this.passwordType === 'password' ? '#79838E' : 'currentColor'"
            @click="togglePassword"
          ></VueButton
        ></VueInput>
        <div class="space"></div>
        <VueInput
          :type="passwordType"
          name="newPasswordRepeat"
          id="newPasswordRepeat"
          validatorName="Şifre"
          label="Yeni Şifre Tekrar"
          v-model="formModel.newPasswordRepeat"
          :messageOnTop="true"
          validationRules="required|max:20|min:8|confirmed:newPassword|atLeastTwoChar"
          :validState="true"
        >
          <VueButton
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            as="a"
            :iconName="getIcon.name"
            :iconSize="{ width: getIcon.width, height: getIcon.height }"
            :iconColor="this.passwordType === 'password' ? '#79838E' : 'currentColor'"
            @click="togglePassword"
          ></VueButton>
        </VueInput>
      </VueForm>
      <div>
        <BrandButton
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click="handleSubmit"
          >GÜNCELLE</BrandButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { mapGetters } from 'vuex';
import { Settings } from '@/services/Api/index';

import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';

export default {
  name: 'ChangePassword',
  components: {
    VueInput,
    VueButton,
    VueForm,
    BrandButton,
  },
  data: () => {
    return {
      formModel: {
        id: '',
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
      },
      submitted: false,
      passwordType: 'password',
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
    routes() {
      return {
        auth: RoutesAthentication,
      };
    },
    ...mapGetters('auth', ['getUserModel']),
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();

      this.formModel.id = this.getUserModel.id || '';
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.submitted = true;
        Settings.changePassword(this.formModel).then(res => {
          if (res && res.data.Data) {
            this.$router.push('/auth/login/select');
          }
        });
      }
    },

    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
  },
};
</script>
<style scoped lang="scss">
.form-wrapper {
  width: 663px;
  margin: 60px auto;
}
/deep/ form,
.space {
  margin-bottom: 20px;
}
</style>
